// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-comparador-js": () => import("./../../../src/templates/comparador.js" /* webpackChunkName: "component---src-templates-comparador-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-registration-js": () => import("./../../../src/templates/registration.js" /* webpackChunkName: "component---src-templates-registration-js" */),
  "component---src-templates-success-js": () => import("./../../../src/templates/success.js" /* webpackChunkName: "component---src-templates-success-js" */)
}

