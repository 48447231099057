const windowGlobal = typeof window !== "undefined" && window;

const initialState = {
  amount:
    typeof window !== "undefined"
      ? Number(windowGlobal.localStorage.getItem("amount"))
      : 2000,
  amountDefault: 2000,
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CURRENT_DATA":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
